import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { StorageServiceService } from 'src/app/services/storage-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html',
})
export class NavbarComponent implements OnInit {
  location: Location;

  public isCollapsed = true;
  listTitles = [
    { path: 'dashboard', title: 'Dashboard' },
    { path: 'student', title: 'Student' },
    { path: 'parent', title: 'Parent' },
    { path: 'tutor', title: 'Tutor' },
    { path: 'institute', title: 'Institute' },
    { path: 'view-tutor', title: 'Tutor Details' },
    { path: 'view-student', title: 'Students Details'},
    { path: 'view-parent', title: 'Parent Details' },
    { path: 'view-grade', title: 'Grade Details' },
    { path: 'subject', title: 'Subject/Course' },
    { path: 'grade', title: 'Grade' },
    { path: 'report', title: 'Report' },
    { path: 'free-resource', title: 'Free Resource' },
    { path: 'package', title: 'package' },
    { path: 'promo', title: 'Promo Code' },
  ];
  @ViewChild('navbar-cmp', { static: false }) button;

  constructor(
    location: Location,
    private storageService: StorageServiceService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.location = location;
  }

  ngOnInit() {
  }
  getTitle() {
    var title = this.location.prepareExternalUrl(this.location.path());
    if (title.charAt(0) === '#') {
      title = title.slice(1);
    } else {
      title =  title.split('/')[1];
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === title) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }
  logout(){
    this.storageService.clearDataFromStorage()
    this.router.navigate(['/login']);
    this.toastr.success("logout successfull");
  
  }
  
}
