import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-grade',
  templateUrl: './view-grade.component.html',
  styleUrls: ['./view-grade.component.scss']
})
export class ViewGradeComponent implements OnInit {

  constructor( private router: Router) { }

  ngOnInit(): void {
  }
  onSubmit(){
    this.router.navigate(['/grade']);
  }
}
