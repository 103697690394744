<!-- <div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
      <sidebar-cmp></sidebar-cmp>
  </div>
  <div class="main-panel">
      <navbar-cmp></navbar-cmp>
      <div class="content">
          <router-outlet></router-outlet>
      </div>
      <footer-cmp></footer-cmp>
  </div>
</div> -->
<ngx-ui-loader></ngx-ui-loader>
<router-outlet></router-outlet>
