<div class="example-header">

  <input class="search mb-3" matInput (keyup)="applyFilter($event.target.value)" placeholder="Search by name">

</div>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
      <td mat-cell *matCellDef="let i=index"> {{i+1}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element?.first_name + " " + element?.last_name}} </td>
    </ng-container>

    <!-- paymentstatus Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email?element.email.email_id:" "}} </td>
    </ng-container>

    <!-- status Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
      <td mat-cell *matCellDef="let element"> {{element.phone?element.phone.phone_no:" "}} </td>
    </ng-container>
    <!-- phone Column -->
    <!-- <ng-container matColumnDef="payment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Payment Status </th>
      <td mat-cell *matCellDef="let element"> {{element?.payment_status}} </td>
    </ng-container> -->

    <!-- action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
      <!-- <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon> -->
      <td mat-cell *matCellDef="let element">
        <i class="fa fa-info-circle" title="View Details" aria-hidden="true" (click)="viewdetails(element._id)"></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10,20]" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
</div>