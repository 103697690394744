<div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title">Class 1</h2>
        </div>
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Sn No</th>
                <th scope="col">Subject Name</th>
                <th scope="col">Active</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">1</td>
                <td>English</td>
                <td><i class="fa fa-check" aria-hidden="true"></i></td>
              </tr>
              <tr>
                <td scope="row">1</td>
                <td>Math</td>
                <td><i class="fa fa-check" aria-hidden="true"></i></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="update ml-auto mr-auto">
      <button
        type="submit"
        class="btn btn-primary btn-round"
        (click)="onSubmit()"
      >
        Go Back
      </button>
    </div>
  </div>