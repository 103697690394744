<div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div *ngIf="status=='REJECTED'" class="col-md-12" style="text-align: center;font-weight: bold;color:red">
              {{status}}
            </div>
            <div *ngIf="status=='VERIFIED'" class="col-md-12" style="text-align: center;font-weight: bold;color:green">
              {{status}}
            </div>
            <div *ngIf="status=='NOT VERIFIED'" class="col-md-12"
              style="text-align: center;font-weight: bold;color:blue">
              {{status}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="item_img" *ngIf='tutor_data?.profile_pic'>
                <img [src]="tutor_data?.profile_pic" alt="" />
              </div>
              <div class="item_img" *ngIf='!tutor_data?.profile_pic'>
                <img src="../../../../assets/profile-picture.png" alt="" />
              </div>
            </div>
            <div class="col-md-6" style="text-align: end;font-size: 40px;">
              <i class="fa fa-file-excel-o" style="cursor:pointer" title="Download Excel" (click)="downloadFile('excel')"
              aria-hidden="true"></i>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-3">
              <h2 class="card-title">Personal Details
                
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>First Name</label>
                <input type="text" class="form-control" placeholder="First Name" 
                  [(ngModel)]="firstName" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Last Name</label>
                <input type="text" class="form-control" placeholder="Last Name" 
                  [(ngModel)]="lastName" />
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label>Email address</label>
                <input type="email" class="form-control" placeholder="Email" [value]="tutor_data?.email?.email_id"
                  [(ngModel)]="email" disabled />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Phone No.</label>
                <input type="text" class="form-control" placeholder="Phone No" [(ngModel)]="phone"
                   />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Gender</label>
                <input type="text" class="form-control" placeholder="Gender" [(ngModel)]="gender"
                   disabled />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Address</label>
                <input type="text" class="form-control" placeholder="Home Address" [(ngModel)]="address"
                 />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>City</label>
                <input type="text" class="form-control" placeholder="City" [(ngModel)]="city"
                  />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>State</label>
                <input type="text" class="form-control" placeholder="State" [(ngModel)]="state"
                  />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Country</label>
                <input type="text" class="form-control" placeholder="Country" [(ngModel)]="country"
                   />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Postal Code</label>
                <input type="number" class="form-control" [value]="tutor_data?.zipCode?tutor_data?.zipCode:' ' "
                  [(ngModel)]="zipCode" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Educational Background</h2>
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Sn No</th>
              <th scope="col">Major</th>
              <th scope="col">Institute Name</th>
              <th scope="col">Graduaction Year</th>
              <th scope="col">Certified</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">1</td>
              <td>{{tutor_data?.educational_background?tutor_data?.educational_background.major:" "}}</td>
              <td>{{tutor_data?.educational_background?tutor_data?.educational_background.institute_name:" "}}</td>
              <td>{{tutor_data?.educational_background?tutor_data?.educational_background.year:" "}}</td>
              <td *ngIf="tutor_data?.educational_background?.certified">YES</td>
              <td *ngIf="!tutor_data?.educational_background?.certified">NO</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Professional Background</h2>
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Sn No</th>
              <th scope="col">Work Title</th>
              <th scope="col">Institute Name</th>
              <th scope="col">Year OF Experience</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">1</td>
              <td>{{tutor_data?.professional_background?tutor_data?.professional_background.title:" "}}</td>
              <td>{{tutor_data?.professional_background?tutor_data?.professional_background.institute_name:" "}}</td>
              <td>{{tutor_data?.professional_background?tutor_data?.professional_background.experience:" "}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h2 class="card-title">KYC Details</h2>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h2 class="card-title">ID Documents</h2>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Sn No</th>
                        <th scope="col">Type</th>
                        <th scope="col">Number</th>
                        <th scope="col">Front Image</th>
                        <th scope="col">Back Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">1</td>
                        <td>{{tutor_data?.other_media[0]?.id?tutor_data?.other_media[0]?.id?.docType:" "}}</td>
                        <td>{{tutor_data?.other_media[0]?.id?tutor_data?.other_media[0]?.id?.docNumber:" "}}</td>
                        <td>
                          <div class="item_img">
                            <a target="_blank"
                              [href]="tutor_data?.other_media[0]?.id?tutor_data?.other_media[0]?.id?.frontImage:''"
                              download="myimage">
                              <img title="Click on image to download"
                                [src]="tutor_data?.other_media[0]?.id?tutor_data?.other_media[0]?.id?.frontImage:''"
                                alt="" />
                            </a>
                          </div>
                        </td>
                        <td>
                          <div class="item_img">
                            <a target="_blank"
                              [href]="tutor_data?.other_media[0]?.id?tutor_data?.other_media[0]?.id?.backimage:''"
                              download="myimage">
                              <img title="Click on image to download"
                                [src]="tutor_data?.other_media[0]?.id?tutor_data?.other_media[0].id?.backimage:''"
                                alt="" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h2 class="card-title">Educational Docs And Training</h2>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Sn No</th>
                        <th scope="col">Type</th>
                        <th scope="col">Field Of Study</th>
                        <th scope="col">Institute Name</th>
                        <th scope="col">End Year</th>
                        <th scope="col">Start Year</th>
                        <th scope="col">Front Image</th>
                        <th scope="col">Back Image</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let education of edu;let i=index">
                      <tr>
                        <td scope="row">{{i+1}}</td>
                        <td>{{education.eduType}}</td>
                        <td>{{education.fieldOfStudy}}</td>
                        <td>{{education.instituteName}}</td>
                        <td>{{education.endYear}}</td>
                        <td>{{education.startYear}}</td>
                        <!-- <td>{{education.frontImage}}</td> -->
                        <!-- <td>{{education.backimage}}</td> -->
                        <td>
                          <div class="item_img">
                            <a target="_blank" [href]="education.frontImage?education.frontImage:' '"
                              download="myimage">
                              <img title="Click on image to download"
                                [src]="education.frontImage?education.frontImage:' '" alt="" />
                            </a>
                          </div>
                        </td>
                        <td>
                          <div class="item_img">
                            <a target="_blank" [href]="education.backimage?education.backimage:' '" download="myimage">
                              <img title="Click on image to download"
                                [src]="education.backimage?education.backimage:' '" alt="" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h2 class="card-title">Teaching License</h2>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Sn No</th>
                        <th scope="col">License NO</th>
                        <th scope="col">Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">1</td>
                        <td>14545125145</td>
                        <td>
                          <div class="item_img">
                            <a href="assets/download.jpg" download="myimage">
                              <img title="Click on image to download" src="assets/download.jpg" alt="" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <h2 class="card-title">Rating *</h2>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-10">
                      <ngb-rating style="color: red; font-size: 20px" [rate]="rating" [(ngModel)]="rating" [max]="5">
                      </ngb-rating>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col md-6">
                  <div class="card">
                    <div class="card-header">
                      <h2 class="card-title">Amount To Be Paid *</h2>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <input type="text" class="form-control" placeholder="amount" value="$" disabled />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <input type="text" class="form-control" placeholder="amount" [(ngModel)]="amountPaid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col md-6">
                  <div class="card">
                    <div class="card-header">
                      <h2 class="card-title">Amount Show In Front *</h2>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <input type="text" class="form-control" placeholder="amount" value="$" disabled />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <input type="text" class="form-control" placeholder="amount" [(ngModel)]="amountShow" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="button-1">
                <button type="submit" class="btn btn-success btn-round" (click)="onSubmit('verified')">
                  Verified
                </button>
              </div>
            </div>
            <div class="col-md-4">
              <!-- <div class="button-3">
                <button type="submit" class="btn btn-primary btn-round" (click)="onSubmit('not verified')">
                  Not Verified
                </button>
              </div> -->
            </div>
            <div class="col-md-4">
              <div class="button-2">
                <button type="submit" class="btn btn-danger btn-round" (click)="onSubmit('rejected')">
                  Rejected
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Payment Details</h2>
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Sn No</th>
              <th scope="col">Name</th>
              <th scope="col">Branch</th>
              <th scope="col">City</th>
              <th scope="col">IFSC Code</th>
              <th scope="col">Account Number</th>
              <th scope="col">Branch Code</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">1</td>
              <td>{{tutor_data?.bank_details?tutor_data?.bank_details.account_name:" "}}</td>
              <td>{{tutor_data?.bank_details?tutor_data?.bank_details.bank_name:" "}}</td>
              <td>{{tutor_data?.bank_details?tutor_data?.bank_details.branch_name:" "}}</td>
              <td>{{tutor_data?.bank_details?tutor_data?.bank_details.ifsc_code:" "}}</td>
              <td>{{tutor_data?.bank_details?tutor_data?.bank_details.account_number:" "}}</td>
              <td>{{tutor_data?.bank_details?tutor_data?.bank_details.branch_code:" "}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div> -->

<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Area Of Interest</h2>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-5">
            <div class="form-group">
              <label>Grade Level</label>
              <ng-select [items]="grades" bindLabel="grade_level" [multiple]="true" (change)="changeLeagueOwner1()" placeholder="Select Grade" [(ngModel)]="selectGrade">
              </ng-select>
            </div>
          </div>
          <div class="col-md-7">
            <div class="form-group">
              <label>Subject</label>
              <ng-select [items]="subjects" bindLabel="subject_name" [multiple]="true" placeholder="Select Subjects"
                [(ngModel)]="selectSubjects" (change)="changeLeagueOwner()">
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf='tutor_data?.request_session.length'>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Student OnBoard</h2>
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Sn No</th>
              <th scope="col">Student Name</th>
              <th scope="col">Student Email</th>
              <th scope="col">Student Phone</th>
              <th scope="col">Subject</th>
              <th scope="col">Grade</th>
              <th scope="col">Time</th>
              <th scope="col">Time-slot</th>
              <th scope="col">Date</th>
              <th scope="col">Plateform</th>
              <th scope="col">Session Status</th>
            </tr>
          </thead>
          <tbody *ngFor="let data of tutor_data?.request_session;let i=index">
            <tr>
              <td scope="row">{{i+1}}</td>
              <td>{{data.student_id?data.student_id.first_name:""}}</td>
              <td>{{data.student_id?data.student_id.email.email_id:""}}</td>
              <td>{{data.student_id?data.student_id.phone.phone_no:""}}</td>
              <td>{{data.subject_id?data.subject_id.subject_name:""}}</td>
              <td>{{data.grade_id?data.grade_id.grade_level:""}}</td>
              <td>{{data.slot_time}}</td>
              <td>{{data.select_time}}</td>
              <td>{{data.select_date}}</td>
              <td>{{data.plateform}}</td>
              <td>
                <span *ngIf="data.schedule==1">Pending
                </span>
                <span *ngIf="data.schedule==2">Cancel
                </span>
                <span *ngIf="data.schedule==3">Reschedule
                </span>
                <span *ngIf="data.schedule==4">Scheduled
                </span>
                <span *ngIf="data.schedule==5">Completed
                </span>
              </td>
              <!-- <td>-</td> -->
              <!-- <td>Zoom</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <!-- <div class="col-md-12"> -->
    <div class="col-md-6">
      <div class="update ml-auto mr-auto" style="text-align: right;">
        <button type="submit" class="btn btn-success btn-round" (click)="goBack()">
          Go Back
        </button>
      </div>
    </div>
    <div class="col-md-6">
      <div class="update ml-auto mr-auto">
        <button type="submit" class="btn btn-success btn-round" (click)="update()">
          Update
        </button>
      </div>
    </div>
  <!-- </div> -->

</div>