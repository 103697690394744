import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class DownloadFileServiceService {

  constructor() { }

  DownloadInExcel(filename: any, downloadexceldata: any) {
    var filedate = moment(new Date()).format('ll')
    var excelarray = XLSX.utils.json_to_sheet(downloadexceldata)
    var wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, excelarray, 'excelarray')
    XLSX.writeFile(wb, 'Excel ' + filename + ' ' + filedate + '.xlsx')
  }

}
