
<div class="sidebar-wrapper">
  <div class="logo">
     <h3>KUSAEDU</h3> 
  </div>
    <ul class="nav">
        <li *ngFor="let menuItem of menuItems" routerLinkActive="active" class="{{menuItem.class}}">
            <a style="cursor: pointer;" [routerLink]="[menuItem.path]">
                <i class="nc-icon {{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>