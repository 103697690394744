import { ViewChild, AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';
declare var $;
@Component({
  selector: 'app-subject-course',
  templateUrl: './subject-course.component.html',
  styleUrls: ['./subject-course.component.scss']
})
export class SubjectCourseComponent implements OnInit {
  displayedColumns: string[] = ['position', 'subject', 'no_of_tutor', 'no_of_student', 'active', 'action'];
  dataSource: any;
  ELEMENT_DATA: any = []
  subject: String
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private router: Router, private toastr: ToastrService,
    private apiService: ApiServiceService,
    private loader: NgxUiLoaderService, private storageService: StorageServiceService) { }

  ngOnInit(): void {
    this.subjectData()
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;

  }

  viewdetails(data) {
    // debugger
    let active = true
    if (data.active) {
      active = false
    }
    this.loader.start()
    let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
    let Data = {
      user_id: localStorage.user_id,
      active: active,
      id: data._id
    }
    this.apiService
      .postMethod('/admin/subject_grade_status/', Data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.toastr.success(response.message);
          this.subjectData()
          //  console.log(this.dataSource);
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  openModal() {
    $('#exampleModalCenter').show()
  }

  subjectData() {
    this.loader.start()
    let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
    let data = {
      user_id: localStorage.user_id
    }
    this.apiService
      .postMethod('/admin/admin_subject/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.ELEMENT_DATA = response.data
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          //  console.log(this.dataSource);
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  addSubject() {
    if (!this.subject) {
      return this.toastr.error("field is required")
    } else {
      let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
      let data = {
        user_id: localStorage.user_id,
        subject_name: this.subject
      }
      this.apiService
        .postMethod('/admin/add_subject_grade/', data)
        .subscribe((response: any) => {
          if (response.code == 200) {
            console.log(response);
            this.toastr.success(response.message);
            $('#exampleModalCenter').hide()
            this.subjectData()
          } else {
            this.toastr.error(response.err);
          }
        });
    }
  }
  closeModal() {
    $('#exampleModalCenter').hide()
  }
}
