import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {
  package_name: "";
  amount: "";
  session_count: "";
  description: "";
  package_list: any = [];


  constructor(private router: Router, private toastr: ToastrService,
    private apiService: ApiServiceService,
    private loader: NgxUiLoaderService, private storageService: StorageServiceService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getPackage()
  }
  getPackage() {
    this.loader.start()
    this.apiService
      .getMethod('/user/get_package')
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.package_list = response.data

        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  removeFree(id) {
    this.loader.start()
    let data = {
      package_id: id._id,
    }
    this.apiService
      .postMethod('/admin/create_package', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.toastr.success(response.message);
          this.getPackage()
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  submit() {
    if (!this.amount || !this.package_name || !this.session_count || !this.description) {
      return this.toastr.error(" All * field maindatory");

    }
    this.loader.start()
    // let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA')!)
    let data = {
      package_name: this.package_name,
      amount: this.amount,
      session_count: this.session_count,
      description: this.description,
    }
    this.apiService
      .postMethod('/admin/create_package', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          // console.log(response)
          this.toastr.success(response.message);
          this.getPackage()
          this.package_name = '';
          this.amount = '';
          this.session_count = '';
          this.description = '';
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
}
