import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { DownloadFileServiceService } from 'src/app/services/download-file-service.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';

@Component({
  selector: 'app-view-student',
  templateUrl: './view-student.component.html',
  styleUrls: ['./view-student.component.scss']
})
export class ViewStudentComponent implements OnInit {
  student_id: any;
  student_data: any;
  selectGrade: any = '';
  grades: any = [];
  selectSubjects: any = [];
  subjects: any = [];
  firstName: any = '';
  lastName: any = '';
  email: any = '';
  phone: any = '';
  parentName: any = '';
  address: any = '';
  city: any = '';
  state: any = '';
  country: any = '';
  zipCode: String = '';
  school: any = '';
  year: any = '';
  subjectData: any = []
  constructor(private router: Router, private toastr: ToastrService,
    private apiService: ApiServiceService,
    private formBuilder: FormBuilder,
    private loader: NgxUiLoaderService, private storageService: StorageServiceService,
    private route: ActivatedRoute,
    private download: DownloadFileServiceService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.student_id = params.id
    });
    if (this.student_id) {
      this.getData()
      this.getGrades()
      this.getSubjects()
    }
  }

  update() {
    // debugger
    // console.log(this.selectSubjects)
    // return
    this.loader.start()
    let data = {
      user_id: this.student_id,
      first_name: this.firstName,
      last_name: this.lastName,
      // email: this.email,
      parent_name: this.parentName,
      school_name: this.school,
      school_year: this.year,
      phone: this.phone,
      grade_level: [{ grade_id: this.selectGrade._id }],
      country: this.country,
      address: this.address,
      state: this.state,
      city: this.city,
      zipCode: this.zipCode.toString()
    }
    if (this.subjectData.length > 0) {
      data['subject'] = this.subjectData
    }
    this.apiService
      .postMethod('/user/edit_profile/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          // this.student_data = response.data
          console.log(response);
          this.toastr.success(response.message);

          this.router.navigate(['/student']);

        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  getData() {
    // debugger
    this.loader.start()
    let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
    let data = {
      user_id: localStorage.user_id,
      id: this.student_id,
      role: 'student'
    }
    this.apiService
      .postMethod('/admin/get_user_data/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.student_data = response.data
          this.firstName = this.student_data?.first_name
          this.lastName = this.student_data?.last_name
          this.email = this.student_data?.email ? this.student_data?.email?.email_id : ""
          this.phone = this.student_data?.phone ? this.student_data.phone.phone_no : ' '
          this.parentName = this.student_data?.parent_name ? this.student_data.parent_name : ' '
          this.address = this.student_data?.address ? this.student_data?.address : ' '
          this.city = this.student_data?.city ? this.student_data?.city : ' '
          this.state = this.student_data?.state ? this.student_data?.state : ' '
          this.country = this.student_data?.country ? this.student_data?.country : ' '
          this.zipCode = this.student_data?.zipCode ? this.student_data?.zipCode : ' '
          this.school = this.student_data?.school_name ? this.student_data.school_name : ' '
          this.year = this.student_data?.school_year ? this.student_data.school_year : ' '
          this.selectGrade = this.student_data?.grade_data[0]
          this.selectSubjects = this.student_data?.subject_data
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  goBack() {
    this.router.navigate(['/student']);
  }
  getGrades() {
    let data = {
      key: 1
    }
    this.apiService
      .postMethod('/user/get_grade_subjects/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.grades = response.data
          console.log(this.grades);
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  getSubjects() {
    let data = {
      key: 0
    }
    this.apiService
      .postMethod('/user/get_grade_subjects/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.subjects = response.data
          // console.log(this.grades);
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  downloadFile(format: any) {
    // debugger
    if (format == 'pdf') {

    } else if (format == 'excel') {
      if (!this.student_data) {
        return this.toastr.error("No any data for download");
      }
      this.downloadExcelFile(this.student_data)
    }

  }
  downloadExcelFile(e) {
    // debugger
    var exportdata: any = [];
    // resData.forEach((e: any) => {
    let data: any = {
      FirstName: e.first_name ? e.first_name : "",
      lastName: e.last_name ? e.last_name : "",
      Email: e.email ? e.email.email_id : "",
      Phone: e.phone ? e.phone.phone_no : "",
      ParentName: e.parent_name ? e.parent_name : "",
      Address: e.address ? e.address : "",
      City: e.city ? e.city : "",
      State: e.state ? e.state : "",
      Country: e.country ? e.country : "",
      ZipCode: e.zipCode ? e.zipCode : "",
      School: e.school_name ? e.school_name : "",
      Year: e.school_year ? e.school_year : "",
      Grade: e.grade_data[0].grade_level,
      // Subject: e.subject_data,
      // SessionDetails: e.request_session,
      // PackageDetails: e.package_details
    }
    let Subject = []
    for (let i = 0; i < e.subject_data.length; i++) {
      Subject.push(e.subject_data[i].subject_name)
    }
    data['Subject'] = Subject.toString();
    exportdata.push(data);

    e.request_session.forEach(element => {
      let session = {
        TutorName: element.tutor_id ? element.tutor_id.first_name : "",
        TutorEmail: element.tutor_id ? element.tutor_id.email.email_id : "",
        TutorPhone: element.tutor_id ? element.tutor_id.phone.phone_no : "",
        Subject: element.subject_id ? element.subject_id.subject_name : "",
        Grade: element.grade_id ? element.grade_id.grade_level : "",
        Slot: element.slot_time,
        Time: element.select_time,
        Date: element.select_date,
        Plateform: element.plateform,
      }
      exportdata.push(session);

    });
    e.package_details.forEach(details => {
      let packageDetails = {
        PackageName: details.packageId ? details.packageId.package_name : "",
        PackagePrice: details.session_price,
        Count: details.session_count,
      }
      exportdata.push(packageDetails);

    });
    // });
    this.download.DownloadInExcel("Student Data", exportdata);
  }
  changeLeagueOwner() {
    this.subjectData = []
    for (let i = 0; i < this.selectSubjects.length; i++) {
      let data = {
        subject_id: this.selectSubjects[i]._id
      }
      this.subjectData.push(data)
    }
    console.log(this.subjectData)
  }
}
