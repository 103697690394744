import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  list = [
    {
      image: 'assets/download.jpg',
      name: 'harshit',
      star: 4,
    },
    {
      image: 'assets/download.jpg',
      name: 'harshit',
      star: 3,
    },
    {
      image: 'assets/download.jpg',
      name: 'harshit',
      star: 2,
    },
    {
      image: 'assets/download.jpg',
      name: 'harshit',
      star: 5,
    },
  ];
  totalCount: any = {}
  top_rated:any=[]
  constructor(private storageService: StorageServiceService,
    private toastr: ToastrService,
    private apiService: ApiServiceService,
    private loader: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.count()
  }

  count() {
    this.loader.start()
    let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
    let data = {
      user_id: localStorage.user_id
    }
    this.apiService
      .postMethod('/admin/dashboard_count/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          console.log(response);
          this.loader.stop()
          this.totalCount = response.data
          this.top_rated=response.data.top_rated
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
}
