<div class="row">
  <div class="update ml-auto mr-auto">
    <button type="submit" class="btn btn-primary btn-round" (click)="goBack()">
      Go Back
    </button>
  </div>
</div>
<div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="item_img" *ngIf='parent_data?.profile_pic'>
                <img [src]="parent_data?.profile_pic" alt="" />
              </div>
              <div class="item_img" *ngIf='!parent_data?.profile_pic'>
                <img src="../../../../assets/profile-picture.png" alt="" />
              </div>
            </div>
            <div class="col-md-6" style="text-align: end;font-size: 40px;">
              <i class="fa fa-file-excel-o" style="cursor:pointer" title="Download Excel"
                (click)="downloadFile('excel')" aria-hidden="true"></i>
            </div>

          </div>

          <div class="row">
            <div class="col-md-3">
              <h2 class="card-title">Personal Details

              </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>First Name</label>
                <input type="text" class="form-control" placeholder="First Name" [(ngModel)]="firstName" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Last Name</label>
                <input type="text" class="form-control" placeholder="Last Name" [(ngModel)]="lastName" />
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label>Email address</label>
                <input type="email" class="form-control" disabled placeholder="Email" [(ngModel)]="email" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Phone No.</label>
                <input type="text" class="form-control" placeholder="Phone No" [(ngModel)]="phone" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Parent Name</label>
                <input type="text" class="form-control" placeholder="Parent Name" [(ngModel)]="parentName" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Address</label>
                <input type="text" class="form-control" placeholder="Home Address" [(ngModel)]="address" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>City</label>
                <input type="text" class="form-control" placeholder="City" [(ngModel)]="city" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>State</label>
                <input type="text" class="form-control" placeholder="State" [(ngModel)]="state" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Country</label>
                <input type="text" class="form-control" placeholder="Country" [(ngModel)]="country" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Postal Code</label>
                <input type="number" class="form-control" placeholder="ZIP Code" [(ngModel)]="zipCode" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label>School</label>
                <input type="text" class="form-control" placeholder="School" [(ngModel)]="school" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Year</label>
                <input type="text" class="form-control" placeholder="Year" [(ngModel)]="year" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Area Of Interest</h2>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-5">
            <div class="form-group">
              <label>Grade Level</label>
              <ng-select [items]="grades" bindLabel="grade_level" placeholder="Select Grade" [(ngModel)]="selectGrade">
              </ng-select>
            </div>
          </div>
          <div class="col-md-7">
            <div class="form-group">
              <label>Subject</label>
              <ng-select [items]="subjects" bindLabel="subject_name" [multiple]="true" placeholder="Select Subjects"
                [(ngModel)]="selectSubjects" (change)="changeLeagueOwner()">
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Sessions Update</h2>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Remaining Session</label>
              <input type="text" class="form-control" placeholder="Total"
                [value]="parent_data?.total_session_count?parent_data?.total_session_count:0" disabled />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf='parent_data?.package_details.length'>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Package Details</h2>
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Sn No</th>
              <th scope="col">Package Name</th>
              <th scope="col">Package Price</th>
              <th scope="col">Session Count</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of parent_data?.package_details;let i=index">
            <tr>
              <td scope="row">{{i+1}}</td>
              <td>{{item.packageId?item.packageId.package_name:" "}}</td>
              <td>{{item.session_price}}</td>
              <td>{{item.session_count}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf='parent_data?.request_session.length'>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Session Details</h2>
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Sn No</th>
              <th scope="col">Tutor Name</th>
              <th scope="col">Tutor Email</th>
              <th scope="col">Tutor Phone</th>
              <th scope="col">Subject</th>
              <th scope="col">Grade</th>
              <th scope="col">Time</th>
              <th scope="col">Time-slot</th>
              <th scope="col">Date</th>
              <th scope="col">Plateform</th>
              <th scope="col">Session Status</th>
            </tr>
          </thead>
          <tbody *ngFor="let data of parent_data?.request_session;let i=index">
            <tr>
              <td scope="row">{{i+1}}</td>
              <td>{{data.tutor_id?data.tutor_id.first_name:""}}</td>
              <td>{{data.tutor_id?data.tutor_id.email.email_id:""}}</td>
              <td>{{data.tutor_id?data.tutor_id.phone.phone_no:""}}</td>
              <td>{{data.subject_id?data.subject_id.subject_name:""}}</td>
              <td>{{data.grade_id?data.grade_id.grade_level:""}}</td>
              <td>{{data.slot_time}}</td>
              <td>{{data.select_time}}</td>
              <td>{{data.select_date | date:"dd-MM-yyyy"}}</td>
              <td>{{data.plateform}}</td>
              <td>
                <span *ngIf="data.schedule==1">Pending
                </span>
                <span *ngIf="data.schedule==2">Cancel
                </span>
                <span *ngIf="data.schedule==3">Reschedule
                </span>
                <span *ngIf="data.schedule==4">Scheduled
                </span>
                <span *ngIf="data.schedule==5">Completed
                </span>
              </td>
              <!-- <td>-</td> -->
              <!-- <td>Zoom</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="update ml-auto mr-auto">
    <button type="submit" class="btn btn-primary btn-round" (click)="update()">
      Update
    </button>
  </div>
</div>