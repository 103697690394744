<div class="row">
    <div class="col-md-6">
        <div class="update">
            <button type="submit" class="btn btn-success btn-round" (click)="promo()">
                Add Promo
            </button>
        </div>
    </div>
    <!-- <div class="col-md-6">
        <div class="example-header">
            <input class="search" matInput (keyup)="applyFilter($event.target.value)" placeholder="Search by subject" />
        </div>
    </div> -->
</div>
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
            <td mat-cell *matCellDef="let i=index"> {{i+1}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Promo Name</th>
            <td mat-cell *matCellDef="let element"> {{element?.code_name}} </td>
        </ng-container>


        <!-- status Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let element"> {{element?.active}} </td>
        </ng-container>
        <!-- phone Column -->
        <ng-container matColumnDef="website">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Render In Website </th>
        <td mat-cell *matCellDef="let element"> {{element?.website}} </td>
      </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
            <!-- <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon> -->
            <td mat-cell *matCellDef="let element">
                <i class="fa fa-info-circle" title="Update Details" aria-hidden="true"
                    (click)="viewdetails(element)"></i>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10,20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>

<div>
    <!-- Modal -->
    <div class="modal modal-fade" id="exampleModalCenter" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title model-1" id="exampleModalLongTitle">Add Promo</h5>
                    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Code Name *</label>
                                <input type="text" class="form-control" placeholder="Code Name"
                                    [(ngModel)]="codeName" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Value *</label>
                                <input type="text" class="form-control" placeholder="Discount Value"
                                    [(ngModel)]="value" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Discount Type *</label>
                                <div class="input-group">
                                    <select class="custom-select" [(ngModel)]="discountType">
                                        <option selected>$</option>
                                        <option>%</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Description *</label>
                                <input type="text" class="form-control" placeholder="Description"
                                    [(ngModel)]="description" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Start Time *</label>
                                <input type="date" [(ngModel)]="fromDate"  (change)="datePicker()">
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="!showToDate">
                            <div class="form-group">
                                <label>End Time *</label>
                                <input type="date" disabled [(ngModel)]="toDate">

                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="showToDate">
                            <div class="form-group">
                                <label>End Time *</label>
                                <input type="date" [(ngModel)]="toDate" [min]="fromDate">
                            </div>
                        </div>
                    </div>
                    &nbsp;
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Active *</label>
                                <br>
                                <input type="checkbox" [(ngModel)]="active">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Show In Website *</label>
                                <br>
                                <input type="checkbox" [(ngModel)]="showWebsite">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Max Count *</label>
                                <input type="text" class="form-control" placeholder="Max Count"
                                    [(ngModel)]="maxCount" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" (click)="addPromo()">Add</button>
                </div>
            </div>
        </div>
    </div>
</div>