import { ViewChild, AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';
declare var $;
@Component({
  selector: 'app-grade',
  templateUrl: './grade.component.html',
  styleUrls: ['./grade.component.scss']
})
export class GradeComponent implements OnInit {
  displayedColumns: string[] = ['position','grade', 'no_of_tutor', 'no_of_student','active', 'action'];
  dataSource :any;

ELEMENT_DATA :any=[]
grade:string
 @ViewChild(MatPaginator) paginator: MatPaginator;
 @ViewChild(MatSort) sort: MatSort;
  constructor(private router: Router,private toastr: ToastrService,
    private apiService: ApiServiceService,
    private loader: NgxUiLoaderService,private storageService: StorageServiceService) { }

  ngOnInit(): void {
    this.gradeData()
  
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  
  }

  viewdetails(data){
    let active = true
    if (data.active) {
      active = false
    }
    this.loader.start()
    let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
    let Data = {
      user_id: localStorage.user_id,
      active: active,
      id: data._id
    }
    this.apiService
      .postMethod('/admin/subject_grade_status/', Data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.toastr.success(response.message);
          this.gradeData()
          //  console.log(this.dataSource);
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  openModal(){
    $('#exampleModalCenter').show()
  }
  gradeData(){
    // debugger
    this.loader.start()
    let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
    let data={
      user_id:localStorage.user_id
    }
   this.apiService
     .postMethod('/admin/admin_grade/', data)
     .subscribe((response: any) => {
       if (response.code == 200) {
         this.loader.stop()
         this.ELEMENT_DATA=response.data
         this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
         this.dataSource.paginator = this.paginator;
         this.dataSource.sort = this.sort;
        //  console.log(this.dataSource);
       } else {
         this.loader.stop();
         this.toastr.error(response.err);
       }
     });
  }
  addGrade(){
    if(!this.grade){
      return this.toastr.error("field is required")
    }else{
     let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
     let data={
       user_id:localStorage.user_id,
       grade_level:this.grade
     }
    this.apiService
      .postMethod('/admin/add_subject_grade/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          console.log(response);
          this.toastr.success(response.message);
          $('#exampleModalCenter').hide()
          this.gradeData()
        } else {
          this.toastr.error(response.err);
        }
      });
    }
    }
  closeModal(){
    $('#exampleModalCenter').hide()
  }
}
