
<nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <!-- <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div> -->
      <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
    </div>
    <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav">
        <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
          <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink" >
            <i class="fa fa-user" aria-hidden="true"></i>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
            <a ngbDropdownItem (click)="logout()">Log Out Admin</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
