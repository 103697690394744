<div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <h2 class="card-title">Package Details</h2>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Package Name</label>
                                <input type="text" class="form-control" placeholder="Package Name"
                                    [(ngModel)]="package_name" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Amount</label>
                                <input type="text" class="form-control" placeholder="Amount" [(ngModel)]="amount" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Session Count</label>
                                <input type="email" class="form-control" placeholder="Session Count"
                                    [(ngModel)]="session_count" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Description</label>
                                <input type="email" class="form-control" placeholder="Description"
                                    [(ngModel)]="description" />
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="update ml-auto mr-auto">
                            <button type="submit" class="btn btn-primary btn-round" (click)="submit()">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="row" *ngIf='package_list.length>0'>
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h2 class="card-title">Blog List</h2>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Sn No</th>
                            <th scope="col">Package Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Session Count</th>
                            <th scope="col">Description</th>
                            <th scope="col">Action</th>

                        </tr>
                    </thead>
                    <tbody *ngFor="let item of package_list;let i =index">
                        <tr>
                            <td scope="row">{{i +1}}</td>
                            <td>{{item.package_name}}</td>
                            <td>{{item.amount}}</td>
                            <td>{{item.session_count}}</td>
                            <td>{{item.description}}</td>
                            <td> <i class="fa fa-trash" aria-hidden="true" style="font-size: 20px;cursor: pointer;"
                                    (click)="removeFree(item)" title="Delete"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>