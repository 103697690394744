<div class="example-header">
 
  <input class="search mb-3" matInput (keyup)="applyFilter($event.target.value)" placeholder="Search by name">

</div>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
      <td mat-cell *matCellDef="let i=index"> {{i+1}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element?.first_name + " " + element?.last_name}}  </td>
    </ng-container>

    <!-- paymentstatus Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email?element.email.email_id:" "}} </td>
    </ng-container>

    <!-- status Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
      <td mat-cell *matCellDef="let element"> {{element.phone?element.phone.phone_no:" "}} </td>  
    </ng-container>
<!-- phone Column -->
    <ng-container matColumnDef="institute">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Institute Name </th>
      <td mat-cell *matCellDef="let element"> {{element?.institute_name}} </td>  
    </ng-container>

    <!-- action Column -->
     <!-- <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
      <td mat-cell *matCellDef="let element"> 
      <i class="fa fa-info-circle" title="View Details" aria-hidden="true" (click)="openModal(element)"></i>
    </td>  
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10,20]"
                 showFirstLastButtons 
                 aria-label="Select page of periodic elements">
  </mat-paginator>
</div>

  





  <div>
<!-- Modal -->
<div class="modal modal-fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle" style="font-size: x-large;">
          <!-- {{modelData.institute_name}} -->
          Institution Details
        </h5>
        <button type="button" class="close" (click)="closeModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- {{modelData.institute_message}} -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
      </div>
    </div>
  </div>
</div>
  </div>