<div class="row">
  <div class="col-md-6">
    <div class="update">
      <button type="submit" class="btn btn-success btn-round" (click)="openModal()">
        Add Grade
      </button>
    </div>
  </div>
  <div class="col-md-6">
    <div class="example-header">
      <input class="search" matInput (keyup)="applyFilter($event.target.value)" placeholder="Search by grade" />
    </div>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>

    <!-- grade Column -->
    <ng-container matColumnDef="grade">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Grade</th>
      <td mat-cell *matCellDef="let element">{{ element?.grade }}</td>
    </ng-container>

    <!-- no_of_tutor Column -->
    <ng-container matColumnDef="no_of_tutor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>No Of Tutor</th>
      <td mat-cell *matCellDef="let element">{{ element?.no_of_tutor }}</td>
    </ng-container>

    <!-- no_of_student Column -->
    <ng-container matColumnDef="no_of_student">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>No Of Student</th>
      <td mat-cell *matCellDef="let element">{{ element?.no_of_student }}</td>
    </ng-container>

    <!-- active Column -->
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
      <td mat-cell *matCellDef="let element">{{ element?.active }}</td>
    </ng-container>
    <!-- action Column -->

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
      <td mat-cell *matCellDef="let element">
        <!-- <i class="fa fa-delete" title="View Details" aria-hidden="true" (click)="viewdetails(element)"></i> -->
        <input type="checkbox" (click)="viewdetails(element)">
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
</div>

<div>



  <!-- Modal -->
  <div class="modal modal-fade" id="exampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title model-1" id="exampleModalLongTitle">Add Grade</h5>
          <button type="button" class="close" (click)="closeModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Grade</label>
            <input type="text" class="form-control" [(ngModel)]="grade" name="grade" placeholder="Grade *" value="" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" (click)="addGrade()">Add </button>
        </div>
      </div>
    </div>
  </div>
</div>